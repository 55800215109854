* {
    margin: 0px;
    padding: 0px;

}



.trigger {
    color: #fff;
    font-size: 20px;
    padding-left: 10px;
}


.ant-menu-item-selected {
    background-color: #398378 !important;
    color: #fff !important;
}

.ant-layout-header {
    background-color: #398378 !important;
}
.ant-table-tbody{
    padding: 5px !important;
  }
  .ant-table-tbody > tr > td{
    padding: 5px !important;
  }